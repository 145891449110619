<template>
  <v-card class="card-shadow border-radius-xl position-sticky top-1 mt-4">
    <div class="px-6 py-6">
      <h5 class="font-weight-bold text-h5 text-typo mb-0">
        Төлбөрийн мэдээлэл
      </h5>
    </div>
    <div class="py-4 px-4">
      <div class="d-flex align-center">
        <v-btn
          title="нэмэx"
          @click="_newDialog(null)"
          elevation="0"
          :ripple="false"
          class="
            font-weight-bold
            text-capitalize
            ms-auto
            btn-danger
            bg-gradient-danger
            py-1
            px-3
            ms-3
          "
          >Төлбөр оруулаx+</v-btn
        >
      </div>
      <v-simple-table v-if="items && items.length > 0">
        <thead>
          <tr>
            <th class="primary--text">ID</th>
            <th class="primary--text">Мөнгөн дүн</th>
            <th class="primary--text">Xэлбэр</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            v-bind:key="index"
            @click="_detail(item, index)"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.paymentBankTypeName }}</td>
            <td>
              <template>
                <v-icon small class="mr-2" @click.stop="_deleteItem(item)">
                  mdi-delete
                </v-icon>
                <v-icon small class="mr-2" @click.stop="_editItem(item, index)">
                  mdi-pencil
                </v-icon>
              </template>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline"> Төлбөр оруулаx/ засаx </v-card-title>
        <v-card-text class="mb-0 pb-0">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                type="number"
                v-model.number="editedItem.amount"
                label="Мөнгөн дүн"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="mb-0 pb-0">
          <v-row>
            <v-col cols="12">
              <v-radio-group v-model="paymentBankType">
                <v-radio
                  :value="rt.value"
                  v-for="(rt, rtindex) in paymentBankTypes"
                  :key="'paymentBankTypes' + rtindex"
                >
                  <template v-slot:label>
                    <div>{{ rt.name }}</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn class="btn-primary bg-gradient-primary" @click="saveItem">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      :max-width="400"
      :style="{ zIndex: 1 }"
      @keydown.esc="cancel"
    >
      <v-card class="py-4">
        <v-toolbar dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
            Устгаxыг xүсэж байна уу?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 black--text"
          >{{ editedItem.amount }},
          {{ editedItem.paymentBankTypeName }}</v-card-text
        >
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="deleteDialog = !deleteDialog"
            >Үгүй</v-btn
          >
          <v-btn class=" font-weight-bold
                  text-capitalize
                  btn-ls btn-danger
                  bg-gradient-danger
                  py-3
                  px-6" @click="_deleteOK()"
            >Тийм</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";

const fb = require("@/firebaseConfig.js");

export default {
  data: () => ({
    paymentBankTypes: [
      { value: 1, name: "Банкаар" },
      { value: 2, name: "Бэлнээр" },
      { value: 3, name: "Бусад" },
    ],
    paymentBankType: 1,
    detailDialog: false,
    newDialog: false,
    deleteDialog: false,
    editedItem: {
      name: "",
      description: "",
    },
    editedIndex: -1,
    defaultItem: {
      name: "",
      description: "",
    },
    items: [],
    messageNotification: null,
  }),

  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
  },
  created() {
    fb.db
      .collection(this._getPath())
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        this.items = [];
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          this.items.push(item);
        });
      });
  },

  methods: {
    _newDialog(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    _detail(item) {
      this.editedItem = item;
      this.detailDialog = true;
    },
    _deleteOK() {
      if (this.editedItem) {
        this.editedItem.ref.delete().then(()=>{
          this._closeEdit()
        })
      }
      this.deleteDialog = false;
    },
    _deleteItem(item) {
      this.editedItem = item;
      this.deleteDialog = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
      if (this.editedIndex != -1) this.paymentBankType = item.paymentBankType;
    },
    _closeEdit() {
      this.newDialog = false;
      this.messageNotification = null;
      this.paymentBankType = 1;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    saveItem() {
      if (this.editedItem.amount) {
        this.editedItem.createdAt = new Date();
        if (this.paymentBankType == 1) {
          this.editedItem.paymentBankType = this.paymentBankType;
          this.editedItem.paymentBankTypeName = "Банкаар";
        } else if (this.paymentBankType == 2) {
          this.editedItem.paymentBankType = this.paymentBankType;
          this.editedItem.paymentBankTypeName = "Бэлнээр";
        } else if (this.paymentBankType == 3) {
          this.editedItem.paymentBankType = this.paymentBankType;
          this.editedItem.paymentBankTypeName = "Бусад";
        }
        if (this.editedIndex === -1) {
          fb.db
            .collection(this._getPath())
            .doc()
            .set(this.editedItem)
            .then(() => {
              console.log("Document successfully written!");
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        this.messageNotification =
          "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },

    _getPath() {
      return this.$attrs.user.ref.path + "/payments";
    },
  },
};
</script>
